import { Box } from "@mui/material";
import { NavigationEntry } from "./NavigationEntry";
import NavigationMenuComponent from "./NavigationMenuComponent";

interface Props {
  selectedNavigationEntry: NavigationEntry;
  setSelectedNavigationEntry: (newEntry: NavigationEntry) => void;
}

const NavigationSidebar = ({
  selectedNavigationEntry,
  setSelectedNavigationEntry,
}: Props) => {
  return (
    <Box
      component="nav"
      sx={{ display: { xs: "none", sm: "block" } }}
      aria-label="navigation"
    >
      <Box position="fixed">
        <NavigationMenuComponent
          selectedNavigationEntry={selectedNavigationEntry}
          setSelectedNavigationEntry={setSelectedNavigationEntry}
        />
      </Box>
      <Box visibility="hidden">
        <NavigationMenuComponent
          selectedNavigationEntry={selectedNavigationEntry}
          setSelectedNavigationEntry={setSelectedNavigationEntry}
        />
      </Box>
    </Box>
  );
};

export default NavigationSidebar;
