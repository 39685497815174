import { initializeApp } from "firebase/app";
import { getAuth, getIdTokenResult } from "firebase/auth";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
  apiKey: process.env.API_KEY,
  authDomain: process.env.AUTH_DOMAIN,
  projectId: process.env.PROJECT_ID,
  storageBucket: process.env.STORAGE_BUCKET,
  messagingSenderId: process.env.MESSAGING_SENDER_ID,
  appId: process.env.APP_ID,
};

const firebase = initializeApp(firebaseConfig);

const getCurrentUser = () => {
  const user = getAuth(firebase).currentUser;
  if (user === null) {
    throw new Error("User not logged in");
  } else {
    return user;
  }
};

const getCurrentAccountId = async () => {
  const user = getCurrentUser();
  let token = await getIdTokenResult(user);
  if (token.claims["account_id"] === undefined) {
    token = await getIdTokenResult(user, true);
  }
  return token.claims["account_id"] as string;
};

export const firebaseApplication = {
  getFirestore: () => getFirestore(firebase),
  getAuth: () => getAuth(firebase),
};

export const authenticationService = {
  getCurrentUser: getCurrentUser,
  getCurrentAccountId: getCurrentAccountId,
};
