import { AppBar, Box, IconButton, Toolbar, Typography } from "@mui/material";
import { ArrowBack, Menu } from "@mui/icons-material";
import { useLocation, useNavigate } from "react-router-dom";

interface Props {
  title: string;
}

const MenuAppBar = ({ title }: Props) => {
  const navigate = useNavigate();
  const location = useLocation();
  const nestedRoute = (location.pathname.match(/\//g) || []).length > 1;
  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="static">
        <Toolbar>
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="back"
            sx={{ mr: 2, display: nestedRoute ? "block" : "none" }}
            onClick={() => navigate(-1)}
          >
            <ArrowBack />
          </IconButton>
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            sx={{ mr: 2, display: nestedRoute ? "none" : "block" }}
          >
            <Menu />
          </IconButton>
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            {title}
          </Typography>
        </Toolbar>
      </AppBar>
    </Box>
  );
};

export default MenuAppBar;
