import {
  Avatar,
  Box,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import User from "./User";
import { userRepository } from "./UserRepository";

export default function UsersList() {
  const [users, setUsers] = useState<User[]>([]);

  useEffect(() => {
    userRepository.findUsers().then((users) => setUsers(users));
  }, []);
  return (
    <Box width="100%">
      <List>
        {users.map((user) => (
          <ListItem key={user.email} alignItems="flex-start">
            <ListItemAvatar>
              <Avatar
                alt={user.display_name ?? user.email}
                src={user.photo_url}
              />
            </ListItemAvatar>
            <ListItemText
              primary={user.display_name ?? user.email}
              secondary={
                <Typography
                  sx={{ display: "inline" }}
                  component="span"
                  variant="body2"
                  color="text.primary"
                >
                  {user.display_name ? user.email : ""}
                </Typography>
              }
            />
          </ListItem>
        ))}
      </List>
    </Box>
  );
}
