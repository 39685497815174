import {
  Box,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import { useEffect, useState } from "react";
import User, { getDisplayName } from "../user/User";
import { userRepository } from "../user/UserRepository";
import Device, { getDeviceName } from "./Device";
import DeviceIcon from "./DeviceIcon";
import DeleteIcon from "@mui/icons-material/Delete";
import LockResetIcon from "@mui/icons-material/LockReset";
import { deviceRepository } from "./DeviceRepository";
import { UnlockComponent } from "./UnlockComponent";
import { ApproveDialogComponent } from "../component/ApproveDialogComponent";

export default function DevicesListComponent() {
  const [devices, setDevices] = useState<Device[]>([]);
  const [users, setUsers] = useState<User[]>([]);
  const [currentDevice, setCurrentDevice] = useState<Device>();
  const [unlockDialogOpen, setUnlockDialogOpen] = useState<boolean>(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState<boolean>(false);
  const currentDeviceId = deviceRepository.findCurrentDeviceId();

  useEffect(() => {
    deviceRepository.findDevices().then((devices) => setDevices(devices));
    userRepository.findUsers().then((users) => setUsers(users));
  }, []);
  return (
    <Box sx={{ width: "100%" }}>
      <Box width="100%">
        <List>
          {devices.map((device) => (
            <ListItem
              key={device.device_id}
              alignItems="flex-start"
              secondaryAction={
                device.device_id === currentDeviceId || device.unlocked ? (
                  <IconButton
                    edge="end"
                    aria-label="delete"
                    onClick={() => {
                      setCurrentDevice(device);
                      setDeleteDialogOpen(true);
                    }}
                  >
                    <DeleteIcon />
                  </IconButton>
                ) : (
                  <IconButton
                    edge="end"
                    aria-label="unlock"
                    onClick={() => {
                      setCurrentDevice(device);
                      setUnlockDialogOpen(true);
                    }}
                  >
                    <LockResetIcon />
                  </IconButton>
                )
              }
            >
              <ListItemIcon>
                <DeviceIcon deviceType={device.device_type} />
              </ListItemIcon>
              <ListItemText
                primary={getDeviceName(device)}
                secondary={getDisplayName(users, device.user_id)}
              />
            </ListItem>
          ))}
        </List>
      </Box>
      <UnlockComponent
        open={unlockDialogOpen}
        device={currentDevice}
        userName={getDisplayName(users, currentDevice?.user_id ?? "")}
        onClose={() => {
          setCurrentDevice(undefined);
          setUnlockDialogOpen(false);
        }}
        onDelete={() => {
          if (currentDevice) {
            setDevices(
              devices.filter(
                (device) => device.device_id !== currentDevice.device_id
              )
            );
          }
          setCurrentDevice(undefined);
          setUnlockDialogOpen(false);
        }}
      />
      <ApproveDialogComponent
        open={deleteDialogOpen}
        title="Remove device"
        text={`Do you want to remove the ${currentDevice?.device_type} device
      ${currentDevice && getDeviceName(currentDevice)}.
      This device will not be able to see your documents any more`}
        approveText="Remove"
        cancelText="Cancel"
        onApprove={() => {
          if (currentDevice) {
            deviceRepository.deleteDevice(currentDevice.device_id);
            setDeleteDialogOpen(false);
            setDevices(
              devices.filter(
                (device) => device.device_id !== currentDevice.device_id
              )
            );
            setCurrentDevice(undefined);
          }
        }}
        onCancel={() => setDeleteDialogOpen(false)}
      />
    </Box>
  );
}
