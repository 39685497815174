import {
  deleteDoc,
  collection,
  collectionGroup,
  doc,
  getDocs,
  query,
  where,
  setDoc,
} from "firebase/firestore";
import AccountInvitation from "./AccountInvitation";
import { firebaseApplication } from "../firebase/Firebase";

export const accountInvitationRepository = {
  storeAccountInvitation: async (
    account_id: string,
    invitee_email: string,
    creator_user_id: string,
    creator_display_name?: string
  ) => {
    const firestore = firebaseApplication.getFirestore();
    const accounts = collection(firestore, "accounts");
    const account = doc(accounts, account_id);
    const accountInvitations = collection(account, "invitations");
    const accountInvitation = doc(accountInvitations, invitee_email);
    const invitation: AccountInvitation = {
      account_id,
      creator_user_id,
      creator_display_name,
      invitee_email,
    };
    await setDoc(accountInvitation, invitation);
    return invitation;
  },
  findInvitationsByAccountId: async (
    accountId: string
  ): Promise<AccountInvitation[]> => {
    const firestore = firebaseApplication.getFirestore();
    const accounts = collection(firestore, "accounts");
    const account = doc(accounts, accountId);
    const accountInvitations = collection(account, "invitations");
    const invitations = await getDocs(accountInvitations);
    return invitations.docs.map((doc) => doc.data() as AccountInvitation);
  },
  findInvitationsByInviteeEmail: async (email: String) => {
    const firestore = firebaseApplication.getFirestore();
    const accountInvitations = collectionGroup(firestore, "invitations");
    const userInvitations = await getDocs(
      query(accountInvitations, where("invitee_email", "==", email))
    );
    return userInvitations.docs
      .filter((doc) => doc.data().account_id)
      .map((doc) => doc.data() as AccountInvitation);
  },
  deleteInvitation: async (account_id: string, invitee_email: string) => {
    const firestore = firebaseApplication.getFirestore();
    const accounts = collection(firestore, "accounts");
    const account = doc(accounts, account_id);
    const accountInvitations = collection(account, "invitations");
    const accountInvitation = doc(accountInvitations, invitee_email);
    return deleteDoc(accountInvitation);
  },
};
