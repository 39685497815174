import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import { ReactNode } from "react";

type DialogProperties = {
  open: boolean;
  title: string;
  text: string;
  approveText: string;
  cancelText: string;
  onApprove: () => void;
  onCancel: () => void;
  children?: ReactNode;
};

export const ApproveDialogComponent = ({
  open,
  title,
  text,
  approveText,
  cancelText,
  onApprove,
  onCancel,
  children,
}: DialogProperties) => {
  return (
    <Dialog
      open={open}
      onClose={onCancel}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {text}
        </DialogContentText>
      </DialogContent>
      {children}
      <DialogActions>
        <Button onClick={onCancel}>{cancelText}</Button>
        <Button onClick={onApprove} autoFocus>
          {approveText}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
