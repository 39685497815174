import { DeviceType } from "./DeviceType";
import DvrIcon from "@mui/icons-material/Dvr";
import SmartphoneIcon from "@mui/icons-material/Smartphone";
import TabletIcon from "@mui/icons-material/Tablet";
import TvIcon from "@mui/icons-material/Tv";
import WatchIcon from "@mui/icons-material/Watch";
import HomeMiniIcon from "@mui/icons-material/HomeMini";
import ComputerIcon from "@mui/icons-material/Computer";

interface Props {
  deviceType: DeviceType;
}

export default function DeviceIcon({ deviceType }: Props) {
  switch (deviceType) {
    case DeviceType.CONSOLE:
      return <DvrIcon />;
    case DeviceType.MOBILE:
      return <SmartphoneIcon />;
    case DeviceType.TABLET:
      return <TabletIcon />;
    case DeviceType.SMART_TV:
      return <TvIcon />;
    case DeviceType.WEARABLE:
      return <WatchIcon />;
    case DeviceType.EMBEDDED:
      return <HomeMiniIcon />;
    default:
      return <ComputerIcon />;
  }
}
