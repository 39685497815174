import { Box, Fab, ImageList, ImageListItem, SxProps } from "@mui/material";
import { useEffect, useState } from "react";
import AddIcon from "@mui/icons-material/Add";
import { documentMetadataRepository } from "./DocumentMetadataRepository";
import { documentService } from "./DocumentService";
import { Https, InsertDriveFileOutlined } from "@mui/icons-material";
import Document from "./Document";

const DocumentsPage = () => {
  const [files, setFiles] = useState<File[]>([]);
  const [documents, setDocuments] = useState<Document[]>([]);
  const fabStyle = {
    position: "absolute",
    bottom: { xs: "72", sm: "16" },
    right: 16,
    zIndex: 1050,
  } as SxProps;

  useEffect(() => {
    if (files.length > 0) {
      documentService.storeDocument(files[0]).then((newDocument) => {
        setDocuments([...documents, newDocument]);
        setFiles([]);
      });
    }
  }, [files, documents]);
  useEffect(() => {
    documentMetadataRepository
      .findDocumentMetadata()
      .then((documentMetadata) =>
        Promise.all(
          documentMetadata.map((metadata) =>
            documentService.getDocument(metadata)
          )
        ).then((documents) => setDocuments(documents))
      );
  }, []);

  return (
    <Box flex={3}>
      <ImageList sx={{ width: 500, height: 450 }} cols={3} rowHeight={164}>
        {documents.map((document) => {
          if (document.content) {
            const blob = new Blob([document.content]);
            const url = URL.createObjectURL(blob);
            return (
              <ImageListItem key={document.documentId}>
                <img src={url} alt={document.name} loading="lazy" />
              </ImageListItem>
            );
          } else {
            return (
              <InsertDriveFileOutlined key={document.documentId}>
                <Https></Https>
              </InsertDriveFileOutlined>
            );
          }
        })}
      </ImageList>
      <Fab sx={fabStyle} color="primary" aria-label="add" component="label">
        <AddIcon />
        <input
          type="file"
          name="upload"
          onChange={(event: any) => setFiles(event.target.files)}
          style={{ display: "none" }}
        />
      </Fab>
    </Box>
  );
};
export default DocumentsPage;
