import { Chat, Home, Settings } from "@mui/icons-material";
import ImageIcon from "@mui/icons-material/Image";
import {
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@mui/material";
import { Link } from "react-router-dom";
import { NavigationEntry } from "./NavigationEntry";

interface Props {
  selectedNavigationEntry: NavigationEntry;
  setSelectedNavigationEntry: (newEntry: NavigationEntry) => void;
}

const NavigationMenuComponent = ({
  selectedNavigationEntry,
  setSelectedNavigationEntry,
}: Props) => {
  const navigateToHome = () => setSelectedNavigationEntry(NavigationEntry.HOME);
  const navigateToDocuments = () =>
    setSelectedNavigationEntry(NavigationEntry.DOCUMENTS);
  const navigateToContacts = () =>
    setSelectedNavigationEntry(NavigationEntry.CONTACTS);
  const navigateToSettings = () =>
    setSelectedNavigationEntry(NavigationEntry.SETTINGS);
  return (
    <List>
      <ListItem component={Link} to="/" onClick={navigateToHome}>
        <ListItemButton
          selected={selectedNavigationEntry === NavigationEntry.HOME}
        >
          <ListItemIcon>
            <Home />
          </ListItemIcon>
          <ListItemText sx={{ display: { sm: "none", md: "block" } }}>
            <Typography color="textPrimary">Home</Typography>
          </ListItemText>
        </ListItemButton>
      </ListItem>
      <ListItem component={Link} to="/documents" onClick={navigateToDocuments}>
        <ListItemButton
          selected={selectedNavigationEntry === NavigationEntry.DOCUMENTS}
        >
          <ListItemIcon>
            <ImageIcon />
          </ListItemIcon>
          <ListItemText sx={{ display: { sm: "none", md: "block" } }}>
            <Typography color="textPrimary">Documents</Typography>
          </ListItemText>
        </ListItemButton>
      </ListItem>
      <ListItem component={Link} to="/contacts" onClick={navigateToContacts}>
        <ListItemButton
          selected={selectedNavigationEntry === NavigationEntry.CONTACTS}
        >
          <ListItemIcon>
            <Chat />
          </ListItemIcon>
          <ListItemText sx={{ display: { sm: "none", md: "block" } }}>
            <Typography color="textPrimary">Chat</Typography>
          </ListItemText>
        </ListItemButton>
      </ListItem>
      <ListItem component={Link} to="/settings" onClick={navigateToSettings}>
        <ListItemButton
          selected={selectedNavigationEntry === NavigationEntry.SETTINGS}
        >
          <ListItemIcon>
            <Settings />
          </ListItemIcon>
          <ListItemText sx={{ display: { sm: "none", md: "block" } }}>
            <Typography color="textPrimary">Settings</Typography>
          </ListItemText>
        </ListItemButton>
      </ListItem>
    </List>
  );
};

export default NavigationMenuComponent;
