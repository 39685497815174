import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import { documentService } from "../document/DocumentService";
import Device, { getDeviceName } from "./Device";
import { deviceRepository } from "./DeviceRepository";

type UnlockProperties = {
  open: boolean;
  device?: Device;
  userName?: string;
  onClose: () => void;
  onDelete: () => void;
};

export const UnlockComponent = ({
  open,
  device,
  userName,
  onClose,
  onDelete,
}: UnlockProperties) => {
  return (
    <Dialog
      open={open}
      onClose={() => {}}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        {`Unlock Device ${device?.device_type} ${
          device && getDeviceName(device)
        }?`}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          Do you want to unlock the ${device?.device_type} device $
          {device && getDeviceName(device)}
          {userName ?? " of user " + userName}. This may take a while.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button
          onClick={() => {
            device && deviceRepository.deleteDevice(device.device_id);
            onDelete();
          }}
        >
          Remove Device
        </Button>
        <Button
          onClick={() => {
            device && documentService.unlockDocuments([device]);
            onClose();
          }}
          autoFocus
        >
          Unlock
        </Button>
      </DialogActions>
    </Dialog>
  );
};
