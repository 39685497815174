import {
  collection,
  doc,
  getDocs,
  query,
  setDoc,
  where,
} from "firebase/firestore";
import {
  authenticationService,
  firebaseApplication,
} from "../firebase/Firebase";
import DocumentMetadata from "./DocumentMetadata";
import { userRepository } from "../user/UserRepository";

export const documentMetadataRepository = {
  findDocumentMetadata: async () => {
    userRepository.findUsers();
    const firestore = firebaseApplication.getFirestore();
    const currentUserId = await authenticationService.getCurrentUser().uid;
    const documents = collection(firestore, "documents");
    const documentsOfAccount = await getDocs(
      query(documents, where("share_user_ids", "array-contains", currentUserId))
    );
    return documentsOfAccount.docs.map((doc) => doc.data() as DocumentMetadata);
  },
  createDocumentMetadata: async (documentMetadata: DocumentMetadata) => {
    const firestore = firebaseApplication.getFirestore();
    const currentUser = authenticationService.getCurrentUser();
    const documents = collection(firestore, "documents");
    await setDoc(doc(documents, documentMetadata.document_id), {
      owner_user_id: currentUser.uid,
      share_user_ids: [currentUser.uid],
      document_id: documentMetadata.document_id,
      small_image_id: documentMetadata.small_image_id,
      preview_image_id: documentMetadata.preview_image_id,
      name: documentMetadata.name,
      type: documentMetadata.type,
      size: documentMetadata.size,
    });
  },
};
