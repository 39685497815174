import {
  Box,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import MailIcon from "@mui/icons-material/Mail";
import { useContext, useEffect, useState } from "react";
import { UserContext } from "../user/UserContext";
import AccountInvitation from "./AccountInvitation";
import { accountInvitationRepository } from "./AccountInvitationRepository";
import AccountInvitationButton from "./AccountInvitationButton";

export default function AccountInvitationListComponent() {
  const user = useContext(UserContext);
  const [invitations, setInvitations] = useState<AccountInvitation[]>([]);
  useEffect(() => {
    accountInvitationRepository
      .findInvitationsByAccountId(user.account_id)
      .then((invitations) => setInvitations(invitations));
  }, [user]);
  return (
    <Box sx={{ width: "100%" }}>
      <Box width="100%">
        <List>
          {invitations.map((invitation) => (
            <ListItem
              key={invitation.invitee_email}
              alignItems="flex-start"
              secondaryAction={
                <IconButton
                  edge="end"
                  aria-label="delete"
                  onClick={() => {
                    accountInvitationRepository
                      .deleteInvitation(
                        invitation.account_id,
                        invitation.invitee_email
                      )
                      .then(() =>
                        setInvitations(
                          invitations.filter((i) => i !== invitation)
                        )
                      );
                  }}
                >
                  <DeleteIcon />
                </IconButton>
              }
            >
              <ListItemIcon>
                <MailIcon />
              </ListItemIcon>
              <ListItemText
                primary={invitation.invitee_email}
                secondary={
                  "invited by " + invitation.creator_display_name ??
                  invitation.invitee_email
                }
              />
            </ListItem>
          ))}
        </List>
      </Box>
      <AccountInvitationButton
        onInvitationCreated={(invitation) =>
          setInvitations([invitation, ...invitations])
        }
      />
    </Box>
  );
}
