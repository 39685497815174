import { Box } from "@mui/material";
import NotificationListComponent from "./notification/NotificationListComponent";

export default function HomePage() {
  return (
    <Box flex={3}>
      <NotificationListComponent />
    </Box>
  );
}
