import {
  addDoc,
  collection,
  collectionGroup,
  doc,
  getDoc,
  getDocs,
  query,
  setDoc,
  updateDoc,
  where,
  writeBatch,
} from "firebase/firestore";
import {
  authenticationService,
  firebaseApplication,
} from "../firebase/Firebase";
import Device from "./Device";
import DeviceKey from "./DeviceKey";
import DocumentKey from "./DocumentKey";

export const deviceRepository = {
  findCurrentDeviceId: () => {
    return localStorage.getItem("deviceId");
  },

  findDevices: async () => {
    const firestore = firebaseApplication.getFirestore();
    const accountId = await authenticationService.getCurrentAccountId();
    const devices = collection(firestore, "devices");
    const result = await getDocs(
      query(devices, where("account_id", "==", accountId))
    );
    return result.docs.map((doc) => {
      const data = doc.data() as unknown;
      const device = data as Device;
      device.device_id = doc.id;
      return device;
    });
  },

  findDevice: async (deviceId: string) => {
    const firestore = firebaseApplication.getFirestore();
    const devices = collection(firestore, "devices");
    const device = await getDoc(doc(devices, deviceId));
    const deviceResult = device.data() as any;
    deviceResult.device_id = deviceId;
    return deviceResult as Device;
  },

  findDocumentKeys: async (userId: string, deviceId: string) => {
    const firestore = firebaseApplication.getFirestore();
    const devices = query(
      collectionGroup(firestore, "device_keys"),
      where("owner_user_id", "==", userId),
      where("device_id", "==", deviceId)
    );
    const documentKeys = await getDocs(devices);
    return documentKeys.docs.map((doc) => doc.data() as unknown as DocumentKey);
  },

  findDocumentKey: async (documentId: string) => {
    const deviceId = deviceRepository.findCurrentDeviceId();
    if (deviceId === null) {
      return Promise.reject(
        new Error(
          "Device id for decryption of document " + documentId + " missing"
        )
      );
    }
    const firestore = firebaseApplication.getFirestore();
    const documents = collection(firestore, "documents");
    const document = doc(documents, documentId);
    const shares = collection(document, "shares");
    const share = doc(shares, authenticationService.getCurrentUser().uid);
    const devices = collection(share, "device_keys");
    const device = doc(devices, deviceId);
    const documentKey = await getDoc(device);
    return documentKey.data() as unknown as DocumentKey;
  },

  storeShares: async (documentId: string, deviceKeys: DeviceKey[]) => {
    const firestore = firebaseApplication.getFirestore();
    const currentUserId = authenticationService.getCurrentUser().uid;
    const currentDeviceId = deviceRepository.findCurrentDeviceId();
    const share_user_ids = [
      ...new Set(deviceKeys.map((deviceKey) => deviceKey.owner_user_id)),
    ];
    const documents = collection(firestore, "documents");
    const document = doc(documents, documentId);
    const documentMetadata: any = (await getDoc(document)).data();
    const shares = collection(document, "shares");
    await Promise.all(
      share_user_ids.map(async (share_user_id) => {
        const share = doc(shares, share_user_id);
        await setDoc(share, {
          document_id: documentId,
          owner_user_id: share_user_id,
          sharee_user_id: currentUserId,
        });
        documentMetadata.share_user_ids = [
          ...new Set([share_user_id, ...documentMetadata.share_user_ids]),
        ];
        await updateDoc(document, {
          share_user_ids: documentMetadata.share_user_ids,
        });
      })
    );
    await Promise.all(
      deviceKeys.map(async (deviceKey) => {
        const share = doc(shares, deviceKey.owner_user_id);
        const devices = collection(share, "device_keys");
        const device = doc(devices, deviceKey.device_id);
        await setDoc(device, {
          document_id: documentId,
          owner_user_id: deviceKey.owner_user_id,
          device_id: deviceKey.device_id,
          encrypting_device_id: currentDeviceId,
          encrypted_key: deviceKey.encrypted_key,
        } as DocumentKey);
      })
    );
  },

  storeDocumentKeys: async (device: Device, documentKeys: DocumentKey[]) => {
    const firestore = firebaseApplication.getFirestore();
    const documents = collection(firestore, "documents");
    const batch = writeBatch(firestore);
    documentKeys.forEach((documentKey) => {
      const document = doc(documents, documentKey.document_id);
      const shares = collection(document, "shares");
      const share = doc(shares, device.user_id);
      const devices = collection(share, "device_keys");
      batch.set(doc(devices, device.device_id), documentKey);
    });
    await batch.commit();
  },

  storeDevice: async (
    deviceType: string,
    deviceName: string,
    publicKey: JsonWebKey,
    browser?: string
  ) => {
    const firestore = firebaseApplication.getFirestore();
    const user = authenticationService.getCurrentUser();
    const accountId = await authenticationService.getCurrentAccountId();
    const devices = collection(firestore, "devices");
    const device = await addDoc(devices, {
      user_id: user.uid,
      account_id: accountId,
      device_type: deviceType,
      browser_name: browser,
      device_name: deviceName,
      public_key: JSON.stringify(publicKey),
    });
    localStorage.setItem("deviceId", device.id);
  },

  deleteDevice: async (deviceId: string) => {
    const firestore = firebaseApplication.getFirestore();
    const documents = collection(firestore, "documents");
    const batch = writeBatch(firestore);
    const deviceReference = doc(firestore, "devices", deviceId);
    const deviceDocument = await getDoc(deviceReference);
    const device = deviceDocument.data() as any as Device;
    const ownerId = device.user_id;
    const documentKeys = await deviceRepository.findDocumentKeys(
      ownerId,
      deviceId
    );
    documentKeys.forEach((key) => {
      const document = doc(documents, key.document_id);
      const shares = collection(document, "shares");
      const share = doc(shares, ownerId);
      const devices = collection(share, "device_keys");
      const documentKey = doc(devices, deviceId);
      batch.delete(documentKey);
    });
    batch.delete(deviceReference);
    return batch.commit();
  },
};
