import Device from "../device/Device";
import DeviceKey from "../device/DeviceKey";
import { deviceRepository } from "../device/DeviceRepository";
import { deviceService } from "../device/DeviceService";
import DocumentKey from "../device/DocumentKey";
import { authenticationService } from "../firebase/Firebase";
import { cryptoRepository } from "./CryptoRepository";

export const cryptoService = {
  createUUID: () => {
    return crypto.randomUUID();
  },

  encrypt: async (
    documentId: string,
    buffers: ArrayBuffer[]
  ): Promise<ArrayBuffer[]> => {
    if (buffers.length === 0) {
      return Promise.resolve([]);
    }
    const keyPair = await getKeyPair();
    const symmetricKey = await createSymmetricKey();
    const symmetricJsonWebKey = await exportKey(symmetricKey);
    const devices = await deviceRepository.findDevices();
    const deviceKeys = await Promise.all(
      devices.map((device) =>
        encryptSymmetricKey(symmetricJsonWebKey, device, keyPair)
      )
    );
    const firstEncryptedBuffer = await encrypt(buffers[0], symmetricKey);
    const encryptedBuffers = await Promise.all(
      buffers
        .slice(1)
        .map((furtherBuffer) => encrypt(furtherBuffer, symmetricKey))
    );
    deviceRepository.storeShares(documentId, deviceKeys);
    return [firstEncryptedBuffer, ...encryptedBuffers];
  },

  decrypt: async (documentId: string, content: ArrayBuffer) => {
    const keyPair = await getKeyPair();
    const documentKey = await deviceRepository.findDocumentKey(documentId);
    const encryptingDevice = await deviceRepository.findDevice(
      documentKey.encrypting_device_id
    );
    const decryptedCryptoKey = await decryptSymmetricKey(
      documentKey.encrypted_key,
      encryptingDevice,
      keyPair
    );
    const decryptedContent = await decrypt(content, decryptedCryptoKey);
    return decryptedContent;
  },

  decryptKeyPair: async (userId: string) => {
    const symmetricKey = await cryptoRepository
      .findSymmetricKey(userId)
      .catch(async () => {
        const symmetricKey = await createSymmetricKey();
        const symmetricWebKey = await exportKey(symmetricKey);
        await cryptoRepository.storeSymmetricKey(userId, symmetricWebKey);
        const createdKeyPair = await initializeKeyPair(symmetricKey);
        cryptoRepository.findKeyPair = () => {
          return createdKeyPair.jsonWebKeyPair;
        };
        return symmetricWebKey;
      });
    const encrypedPrivateKey = cryptoRepository.findEncryptedPrivateKey();
    const deviceId = deviceRepository.findCurrentDeviceId();
    const device =
      deviceId !== null
        ? await deviceRepository.findDevice(deviceId).catch(() => null)
        : null;
    const symmetricCryptoKey = await importSymmetricKey(symmetricKey);
    if (encrypedPrivateKey === null || deviceId === null || device === null) {
      const createdKeyPair = await initializeKeyPair(symmetricCryptoKey);
      cryptoRepository.findKeyPair = () => {
        return createdKeyPair.jsonWebKeyPair;
      };
    } else {
      const decryptedPrivateKey = await decrypt(
        hex2buf(encrypedPrivateKey),
        symmetricCryptoKey
      );
      const privateKey = JSON.parse(
        buf2text(decryptedPrivateKey)
      ) as JsonWebKey;
      const publicKey = JSON.parse(device.public_key) as JsonWebKey;
      cryptoRepository.findKeyPair = () => {
        return {
          publicKey,
          privateKey,
        };
      };
    }
  },

  encryptDocumentKeys(
    documentKeys: DocumentKey[],
    device: Device
  ): Promise<DocumentKey[]> {
    const currentDeviceId = deviceRepository.findCurrentDeviceId();
    return Promise.all(
      documentKeys.map(async (documentKey) => {
        const keyPair = await getKeyPair();
        const encryptingDevice = await deviceRepository.findDevice(
          documentKey.encrypting_device_id
        );
        const decryptedSymmetricKey = await decryptSymmetricKey(
          documentKey.encrypted_key,
          encryptingDevice,
          keyPair
        );
        const symmetricJsonWebKey = await exportKey(decryptedSymmetricKey);
        const deviceKey = await encryptSymmetricKey(
          symmetricJsonWebKey,
          device,
          keyPair
        );
        return {
          document_id: documentKey.document_id,
          owner_user_id: device.user_id,
          encrypting_device_id: currentDeviceId,
          encrypted_key: deviceKey.encrypted_key,
        } as DocumentKey;
      })
    );
  },
};

async function encrypt(payload: ArrayBuffer, key: CryptoKey) {
  return crypto.subtle.encrypt(
    { name: "AES-CTR", counter: new Uint8Array(16), length: 16 * 8 },
    key,
    payload
  );
}

async function decrypt(payload: ArrayBuffer, key: CryptoKey) {
  return crypto.subtle.decrypt(
    { name: "AES-CTR", counter: new Uint8Array(16), length: 16 * 8 },
    key,
    payload
  );
}

async function getKeyPair() {
  const keyPair = cryptoRepository.findKeyPair();
  if (keyPair !== null) {
    const privateKey = await importPrivateKey(keyPair.privateKey);
    const publicKey = await importPublicKey(keyPair.publicKey);
    return {
      privateKey,
      publicKey,
    } as CryptoKeyPair;
  } else {
    const user = authenticationService.getCurrentUser();
    const symmetricJsonWebKey = await cryptoRepository.findSymmetricKey(
      user.uid
    );
    const symmetricKey = await importSymmetricKey(symmetricJsonWebKey);
    const createdKeyPair = await initializeKeyPair(symmetricKey);
    return createdKeyPair.cryptoKeyPair;
  }
}

async function createSymmetricKey() {
  return crypto.subtle.generateKey(
    { name: "AES-CTR", length: 256 }, // AES in "counter" mode
    true, // Allow exporting the key
    ["encrypt", "decrypt"]
  );
}

async function initializeKeyPair(symmetricKey: CryptoKey) {
  const keyPair = await createKeyPair();
  const privateKey = await exportKey(keyPair.privateKey);
  const publicKey = await exportKey(keyPair.publicKey);
  await deviceService.initializeDevice(publicKey);
  const encryptedPrivateKey = await encryptPrivateKey(privateKey, symmetricKey);
  cryptoRepository.storeEncryptedPrivateKey(encryptedPrivateKey);
  return {
    cryptoKeyPair: keyPair,
    jsonWebKeyPair: {
      privateKey,
      publicKey,
    },
  };
}

async function createKeyPair() {
  return crypto.subtle.generateKey(
    { name: "ECDH", namedCurve: "P-256" },
    true,
    ["deriveKey"]
  );
}

async function importPrivateKey(key: JsonWebKey) {
  return crypto.subtle.importKey(
    "jwk",
    key,
    {
      name: "ECDH",
      namedCurve: "P-256",
    },
    true,
    ["deriveKey"]
  );
}

async function importPublicKey(key: JsonWebKey) {
  return crypto.subtle.importKey(
    "jwk",
    key,
    {
      name: "ECDH",
      namedCurve: "P-256",
    },
    true,
    []
  );
}

async function importSymmetricKey(key: JsonWebKey) {
  return crypto.subtle.importKey(
    "jwk",
    key,
    { name: "AES-CTR", length: 256 },
    true,
    ["encrypt", "decrypt"]
  );
}

async function exportKey(key: CryptoKey) {
  return crypto.subtle.exportKey("jwk", key) as JsonWebKey;
}

async function deriveKey(privateKey: CryptoKey, publicKey: CryptoKey) {
  return crypto.subtle.deriveKey(
    { name: "ECDH", public: publicKey },
    privateKey,
    { name: "AES-CTR", length: 256 },
    true,
    ["encrypt", "decrypt"]
  );
}

async function encryptPrivateKey(
  privateKey: JsonWebKey,
  symmetricKey: CryptoKey
): Promise<string> {
  const encodedPrivateKey = text2buf(JSON.stringify(privateKey));
  const encryptedPrivateKey = await encrypt(encodedPrivateKey, symmetricKey);
  return buf2hex(encryptedPrivateKey);
}

async function encryptSymmetricKey(
  symmetricJsonWebKey: JsonWebKey,
  device: Device,
  keyPair: CryptoKeyPair
): Promise<DeviceKey> {
  const publicKey = await importPublicKey(JSON.parse(device.public_key));
  const derivedKey = await deriveKey(keyPair.privateKey, publicKey);
  const encodedSymmetricKey = text2buf(JSON.stringify(symmetricJsonWebKey));
  const encryptedSymmetricKey = await encrypt(encodedSymmetricKey, derivedKey);
  return {
    owner_user_id: device.user_id,
    device_id: device.device_id,
    encrypting_public_key: device.public_key,
    encrypted_key: buf2hex(encryptedSymmetricKey),
  } as DeviceKey;
}

async function decryptSymmetricKey(
  encryptedSymmetricKey: string,
  device: Device,
  keyPair: CryptoKeyPair
): Promise<CryptoKey> {
  const publicKey = await importPublicKey(JSON.parse(device.public_key));
  const derivedKey = await deriveKey(keyPair.privateKey, publicKey);
  const decryptedJsonWebKey = await decrypt(
    hex2buf(encryptedSymmetricKey),
    derivedKey
  );
  const decodedJsonWebKey = buf2text(decryptedJsonWebKey);
  return importSymmetricKey(JSON.parse(decodedJsonWebKey));
}

function buf2hex(buffer: ArrayBuffer) {
  return Array.prototype.map
    .call(new Uint8Array(buffer), (x) => ("00" + x.toString(16)).slice(-2))
    .join("");
}

function hex2buf(hex: string): ArrayBuffer {
  const bytes = [];
  for (let i = 0; i < hex.length; i += 2) {
    bytes.push(Number.parseInt(hex.slice(i, i + 2), 16));
  }
  return new Uint8Array(bytes);
}

function text2buf(text: string): ArrayBuffer {
  var utf8 = [];
  for (var i = 0; i < text.length; i++) {
    var charcode = text.charCodeAt(i);
    if (charcode < 0x80) utf8.push(charcode);
    else if (charcode < 0x800) {
      utf8.push(0xc0 | (charcode >> 6), 0x80 | (charcode & 0x3f));
    } else if (charcode < 0xd800 || charcode >= 0xe000) {
      utf8.push(
        0xe0 | (charcode >> 12),
        0x80 | ((charcode >> 6) & 0x3f),
        0x80 | (charcode & 0x3f)
      );
    }
    // surrogate pair
    else {
      i++;
      charcode = ((charcode & 0x3ff) << 10) | (text.charCodeAt(i) & 0x3ff);
      utf8.push(
        0xf0 | (charcode >> 18),
        0x80 | ((charcode >> 12) & 0x3f),
        0x80 | ((charcode >> 6) & 0x3f),
        0x80 | (charcode & 0x3f)
      );
    }
  }
  return new Uint8Array(utf8);
}

function buf2text(buffer: ArrayBuffer): string {
  const data = new Uint8Array(buffer);
  let text = "";

  for (let i = 0; i < data.length; i++) {
    var value = data[i];

    if (value < 0x80) {
      text += String.fromCharCode(value);
    } else if (value > 0xbf && value < 0xe0) {
      text += String.fromCharCode(((value & 0x1f) << 6) | (data[i + 1] & 0x3f));
      i += 1;
    } else if (value > 0xdf && value < 0xf0) {
      text += String.fromCharCode(
        ((value & 0x0f) << 12) |
          ((data[i + 1] & 0x3f) << 6) |
          (data[i + 2] & 0x3f)
      );
      i += 2;
    } else {
      // surrogate pair
      var charCode =
        (((value & 0x07) << 18) |
          ((data[i + 1] & 0x3f) << 12) |
          ((data[i + 2] & 0x3f) << 6) |
          (data[i + 3] & 0x3f)) -
        0x010000;

      text += String.fromCharCode(
        (charCode >> 10) | 0xd800,
        (charCode & 0x03ff) | 0xdc00
      );
      i += 3;
    }
  }

  return text;
}
