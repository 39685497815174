import { ManageAccounts } from "@mui/icons-material";
import { Box, Typography } from "@mui/material";
import { Stack } from "@mui/system";
import { useEffect, useState } from "react";
import Account from "./Account";
import AccountInvitationListComponent from "./AccountInvitationListComponent";
import { accountRepository } from "./AccountRepository";

export default function AccountComponent() {
  const [account, setAccount] = useState<Account>();

  useEffect(() => {
    accountRepository.findAccount().then((account) => setAccount(account));
  }, []);
  return (
    <Box width="100%">
      <Stack alignItems="center">
        <ManageAccounts sx={{ padding: 4 }} fontSize="large" />
        <Typography>
          Used storage: {account && account.used_storage / (1024 * 1024)} MB
        </Typography>
        <AccountInvitationListComponent />
      </Stack>
    </Box>
  );
}
