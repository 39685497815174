import {
  collection,
  doc,
  getDocs,
  query,
  updateDoc,
  where,
} from "firebase/firestore";
import {
  authenticationService,
  firebaseApplication,
} from "../firebase/Firebase";
import User from "./User";

export const userRepository = {
  findUsers: async (): Promise<User[]> => {
    const firestore = firebaseApplication.getFirestore();
    const accountId = await authenticationService.getCurrentAccountId();
    const users = collection(firestore, "users");
    const result = await getDocs(
      query(users, where("account_id", "==", accountId))
    );
    return result.docs.map((doc) => doc.data() as unknown as User);
  },
  findUserByUserId: async (userId: string): Promise<User> => {
    const users = await userRepository.findUsers();
    const user = users.find((user) => user.user_id === userId);
    if (!user) {
      return Promise.reject("user not found for id " + userId);
    }
    return user;
  },
  updateAccount: async (user: User, newAccountId: string): Promise<void> => {
    const firestore = firebaseApplication.getFirestore();
    const users = collection(firestore, "users");
    const accountId = await authenticationService.getCurrentAccountId();
    const result = await getDocs(
      query(users, where("account_id", "==", accountId))
    );
    const userDocId = result.docs.find(
      (doc) => doc.data().user_id === user.user_id
    )?.id;
    if (!userDocId) {
      return Promise.reject("user not found for id " + user.user_id);
    }
    user.account_id = newAccountId;
    const userDoc = doc(users, userDocId);
    updateDoc(userDoc, user as any);
  },
};
