import { DeviceType } from "./DeviceType";

export default interface Device {
  account_id: string;
  user_id: string;
  device_id: string;
  browser_name?: string;
  device_type: DeviceType;
  device_name: string;
  public_key: string;
  unlocked: boolean;
}

export const getDeviceName = (device: Device) => {
  return (
    device.device_name +
    (device.browser_name ? " (" + device.browser_name + ")" : "")
  );
};
