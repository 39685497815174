import { Chat, Home, Settings } from "@mui/icons-material";
import ImageIcon from "@mui/icons-material/Image";
import { BottomNavigation, BottomNavigationAction, Paper } from "@mui/material";
import { Link } from "react-router-dom";
import { NavigationEntry } from "./NavigationEntry";

interface Props {
  selectedNavigationEntry: NavigationEntry;
  setSelectedNavigationEntry: (newEntry: NavigationEntry) => void;
}

const AppBottomNavigation = ({
  selectedNavigationEntry,
  setSelectedNavigationEntry,
}: Props) => {
  return (
    <Paper
      sx={{
        position: "fixed",
        bottom: 0,
        left: 0,
        right: 0,
        display: { xs: "block", sm: "none" },
      }}
      elevation={3}
    >
      <BottomNavigation
        showLabels
        value={selectedNavigationEntry}
        onChange={(event, newEntry) => {
          setSelectedNavigationEntry(newEntry);
        }}
      >
        <BottomNavigationAction
          component={Link}
          to="/"
          label="Home"
          value={NavigationEntry.HOME}
          icon={<Home />}
        />
        <BottomNavigationAction
          component={Link}
          to="/documents"
          label="Documents"
          value={NavigationEntry.DOCUMENTS}
          icon={<ImageIcon />}
        />
        <BottomNavigationAction
          component={Link}
          to="/contacts"
          label="Chat"
          value={NavigationEntry.CONTACTS}
          icon={<Chat />}
        />
        <BottomNavigationAction
          component={Link}
          to="/settings"
          label="Settings"
          value={NavigationEntry.SETTINGS}
          icon={<Settings />}
        />
      </BottomNavigation>
    </Paper>
  );
};

export default AppBottomNavigation;
