import { Box } from "@mui/system";
import { Outlet, useLocation } from "react-router-dom";
import SettingsList from "./SettingsList";

export default function SettingsPage() {
  const location = useLocation();
  const isSettingsOverview = location.pathname.endsWith("/settings");
  const displaySettingsOverview = isSettingsOverview ? "block" : "none";
  const displaySettingsDetails = isSettingsOverview ? "none" : "block";
  return (
    <Box display="flex" width="100%">
      <Box
        flex={1}
        sx={{ display: { xs: displaySettingsOverview, sm: "block" } }}
      >
        <SettingsList />
      </Box>
      <Box
        flex={1}
        sx={{ display: { xs: displaySettingsDetails, sm: "block" } }}
      >
        <Outlet />
      </Box>
    </Box>
  );
}
