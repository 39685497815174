import { getAuth, getIdToken } from "firebase/auth";
import { deviceRepository } from "../device/DeviceRepository";
import { documentService } from "../document/DocumentService";
import { authenticationService } from "../firebase/Firebase";
import User from "../user/User";
import { userRepository } from "../user/UserRepository";
import AccountInvitation from "./AccountInvitation";
import { accountInvitationRepository } from "./AccountInvitationRepository";

export const accountInvitationService = {
  invite: async (
    user: User,
    inviteeEmail: string
  ): Promise<AccountInvitation> => {
    const accountId = await authenticationService.getCurrentAccountId();
    return accountInvitationRepository.storeAccountInvitation(
      accountId,
      inviteeEmail,
      user.user_id,
      user.display_name
    );
  },

  approve: async (accountInvitation: AccountInvitation, user: User) => {
    userRepository.updateAccount(user, accountInvitation.account_id);
    refreshIdToken(); // update account_id in id token
    const devices = await deviceRepository.findDevices();
    await documentService.unlockDocuments(devices);
  },

  discard: async (accountInvitation: AccountInvitation) => {
    accountInvitationRepository.deleteInvitation(
      accountInvitation.account_id,
      accountInvitation.invitee_email
    );
  },
};

function refreshIdToken() {
  const auth = getAuth();
  auth.currentUser && getIdToken(auth.currentUser, true);
}
