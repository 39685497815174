import { useEffect, useRef, useState } from "react";
import {
  EmailAuthProvider,
  GoogleAuthProvider,
  onAuthStateChanged,
} from "firebase/auth";
import * as firebaseui from "firebaseui";
import "firebaseui/dist/firebaseui.css";
import { firebaseApplication } from "./firebase/Firebase";
import { userRepository } from "./user/UserRepository";
import User from "./user/User";

interface Props {
  // Callback that will be passed the FirebaseUi instance before it is
  // started. This allows access to certain configuration options such as
  // disableAutoSignIn().
  uiCallback?(ui: firebaseui.auth.AuthUI): void;
  onUserAuthenticated: (user: User) => void;
  className?: string;
}

const Authentication = ({
  uiCallback,
  onUserAuthenticated,
  className,
}: Props) => {
  const [userSignedIn, setUserSignedIn] = useState(false);
  const elementRef = useRef(null);
  const firebaseAuth = firebaseApplication.getAuth();
  const uiConfig = {
    signInSuccessUrl: process.env.URL,
    signInFlow: "popup",
    signInOptions: [
      {
        provider: EmailAuthProvider.PROVIDER_ID,
        requireDisplayName: false,
        signInMethod: EmailAuthProvider.EMAIL_LINK_SIGN_IN_METHOD,
        emailLinkSignIn: function () {
          return {
            url: `${process.env.url}/completeSignIn`,
            dynamicLinkDomain: "imagey.page.link",
            handleCodeInApp: true,
          };
        },
      },
      GoogleAuthProvider.PROVIDER_ID,
    ],
  };

  useEffect(() => {
    // Get or Create a firebaseUI instance.
    const firebaseUiWidget =
      firebaseui.auth.AuthUI.getInstance() ||
      new firebaseui.auth.AuthUI(firebaseAuth);
    if (
      uiConfig.signInFlow === "popup" &&
      !firebaseUiWidget.isPendingRedirect()
    ) {
      firebaseUiWidget.reset();
    }

    // We track the auth state to reset firebaseUi if the user signs out.
    const unregisterAuthObserver = onAuthStateChanged(firebaseAuth, (user) => {
      if (!user && userSignedIn) {
        firebaseUiWidget.reset();
      }
      setUserSignedIn(!!user);
      if (user) {
        userRepository
          .findUserByUserId(user.uid)
          .then((user) => onUserAuthenticated(user));
      }
    });

    // Trigger the callback if any was set.
    if (uiCallback) {
      uiCallback(firebaseUiWidget);
    }

    // Render the firebaseUi Widget.
    // @ts-ignore
    firebaseUiWidget.start(elementRef.current, uiConfig);

    return () => {
      unregisterAuthObserver();
      firebaseUiWidget.reset();
    };
  });

  return <div className={className} ref={elementRef} />;
};

export default Authentication;
