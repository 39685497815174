import { getBytes, getStorage, ref, uploadBytes } from "firebase/storage";
import { authenticationService } from "../firebase/Firebase";

export const documentRepository = {
  findDocument: async (documentId: string) => {
    const storage = getStorage();
    const user = await authenticationService.getCurrentUser();
    const account = ref(storage, user.uid);
    const documents = ref(account, "documents");
    const document = ref(documents, documentId);
    const content = await getBytes(document);
    return content;
  },
  createDocument: async (documentId: string, content: ArrayBuffer) => {
    const storage = getStorage();
    const user = await authenticationService.getCurrentUser();
    const document = ref(storage, "" + user.uid + "/documents/" + documentId);
    const result = await uploadBytes(document, content);
    return result;
  },
};
