import { Devices, Group, ManageAccounts } from "@mui/icons-material";
import {
  Divider,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import { Link, useLocation } from "react-router-dom";

const SettingsList = () => {
  const location = useLocation();
  return (
    <List sx={{ width: 1 }}>
      <ListItem component={Link} to="/settings/account">
        <ListItemButton selected={location.pathname.includes("/account")}>
          <ListItemIcon>
            <ManageAccounts />
          </ListItemIcon>
          <ListItemText primary="Account" />
        </ListItemButton>
      </ListItem>
      <Divider light />
      <ListItem component={Link} to="/settings/users">
        <ListItemButton selected={location.pathname.includes("/users")}>
          <ListItemIcon>
            <Group />
          </ListItemIcon>
          <ListItemText primary="Users" />
        </ListItemButton>
      </ListItem>
      <Divider light />
      <ListItem component={Link} to="/settings/devices">
        <ListItemButton selected={location.pathname.includes("/devices")}>
          <ListItemIcon>
            <Devices />
          </ListItemIcon>
          <ListItemText primary="Devices" />
        </ListItemButton>
      </ListItem>
    </List>
  );
};

export default SettingsList;
