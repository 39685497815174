import { IBrowser, IDevice, IOS, UAParser } from "ua-parser-js";
import { deviceRepository } from "./DeviceRepository";

export const deviceService = {
  initializeDevice: async (publicKey: JsonWebKey) => {
    const deviceParser = new UAParser();
    const browser: IBrowser = deviceParser.getBrowser();
    const device: IDevice = deviceParser.getDevice();
    const os: IOS = deviceParser.getOS();
    const deviceType = device.type ?? "desktop";
    const browserName = browser.name;
    const model = device.model ?? os.name ?? undefined;
    const deviceName = device.vendor
      ? device.vendor + (model ? " " + model : "")
      : model ?? os.name ?? "<unknown>";
    const currentDeviceId = deviceRepository.findCurrentDeviceId();
    if (currentDeviceId !== null) {
      deviceRepository.findDevice(currentDeviceId).catch((e) => {
        deviceRepository.storeDevice(
          deviceType,
          deviceName,
          publicKey,
          browserName
        );
      });
    } else {
      deviceRepository.storeDevice(
        deviceType,
        deviceName,
        publicKey,
        browserName
      );
    }
  },
};
