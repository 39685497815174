import { Box, List, ListItem, ListItemIcon, ListItemText } from "@mui/material";
import MailIcon from "@mui/icons-material/Mail";
import { useContext, useEffect, useState } from "react";
import { UserContext } from "../user/UserContext";
import Notification from "./Notification";
import { notificationService } from "./NotificationService";
import AccountInvitation from "../account/AccountInvitation";
import { NotificationType } from "./NotificationType";
import { ApproveDialogComponent } from "../component/ApproveDialogComponent";
import { accountInvitationService } from "../account/AccountInvitationService";
import User from "../user/User";

export default function NotificationListComponent() {
  const user = useContext(UserContext);
  const [notifications, setNotifications] = useState<Notification<any>[]>([]);
  const [selectedNotification, setSelectedNotification] =
    useState<Notification<any>>();
  useEffect(() => {
    notificationService
      .findNotifications(user)
      .then((notifications) => setNotifications(notifications));
  }, [user]);
  const approveNotificationAction = () => {
    if (selectedNotification) {
      approveNotification(selectedNotification, user);
      setNotifications(notifications.filter((n) => n !== selectedNotification));
    }
    setSelectedNotification(undefined);
  };
  const discardNotificationAction = () => {
    if (selectedNotification) {
      discardNotification(selectedNotification, user);
      setNotifications(notifications.filter((n) => n !== selectedNotification));
    }
    setSelectedNotification(undefined);
  };
  return (
    <>
      <Box sx={{ width: "100%" }}>
        <Box width="100%">
          <List>
            {notifications.map((n) =>
              createNotificationListItem(n, setSelectedNotification)
            )}
          </List>
        </Box>
      </Box>
      <>
        {selectedNotification && (
          <ApproveDialogComponent
            open={true}
            title="Approve"
            text="Accept Invitation"
            approveText="Accept"
            cancelText="Discard"
            onApprove={approveNotificationAction}
            onCancel={discardNotificationAction}
          />
        )}
      </>
    </>
  );
}

function createNotificationListItem(
  notification: Notification<any>,
  setSelectedNotification: (n: Notification<any>) => void
): JSX.Element {
  if (notification.type === NotificationType.ACCOUNT_INVITATION) {
    return createAccountInvitationNotificationListItem(
      notification,
      setSelectedNotification
    );
  } else {
    return (
      <ListItem key={"unknown"} alignItems="flex-start">
        <ListItemText primary={"Unknown Notification"} />
      </ListItem>
    );
  }
}

function createAccountInvitationNotificationListItem(
  notification: Notification<AccountInvitation>,
  setSelectedNotification: (n: Notification<AccountInvitation>) => void
): JSX.Element {
  return (
    <ListItem
      key={notification.data.account_id}
      onClick={() => setSelectedNotification(notification)}
      alignItems="flex-start"
    >
      <ListItemIcon>
        <MailIcon />
      </ListItemIcon>
      <ListItemText
        primary={
          "You are invited by " +
          (notification.data.creator_display_name ??
            notification.data.creator_user_id) +
          " to join the account"
        }
        secondary={
          "When you join that account, all your documents that are not marked as private will be shared with all members of that account"
        }
      />
    </ListItem>
  );
}

async function approveNotification(
  notification: Notification<any>,
  user: User
): Promise<void> {
  if (notification.type === NotificationType.ACCOUNT_INVITATION) {
    await accountInvitationService.approve(
      (notification as Notification<AccountInvitation>).data,
      user
    );
    return;
  } else {
    return Promise.reject("unknown notification type " + notification.type);
  }
}

function discardNotification(
  notification: Notification<any>,
  user: User
): Promise<void> {
  if (notification.type === NotificationType.ACCOUNT_INVITATION) {
    return accountInvitationService.discard(
      (notification as Notification<AccountInvitation>).data
    );
  } else {
    return Promise.reject("unknown notification type " + notification.type);
  }
}
