import AccountInvitation from "../account/AccountInvitation";
import { accountInvitationRepository } from "../account/AccountInvitationRepository";
import User from "../user/User";
import Notification from "./Notification";
import { NotificationType } from "./NotificationType";

export const notificationService = {
  findNotifications: async (user: User): Promise<Notification<any>[]> => {
    const userInvitations =
      await accountInvitationRepository.findInvitationsByInviteeEmail(
        user.email
      );
    const notifications = userInvitations.map(
      createAccountInvitationNotification
    );
    return notifications;
  },
};

function createAccountInvitationNotification(
  accountInvitation: AccountInvitation
): Notification<AccountInvitation> {
  return {
    type: NotificationType.ACCOUNT_INVITATION,
    data: accountInvitation,
  };
}
