import { cryptoService } from "../crypto/CryptoService";
import { documentRepository } from "./DocumentRepository";
import Document from "./Document";
import { documentMetadataRepository } from "./DocumentMetadataRepository";
import DocumentMetadata from "./DocumentMetadata";
import { imageService } from "../image/ImageService";
import Device from "../device/Device";
import { deviceRepository } from "../device/DeviceRepository";
import DocumentKey from "../device/DocumentKey";
import { authenticationService } from "../firebase/Firebase";

export const documentService = {
  storeDocument: async (file: File): Promise<Document> => {
    let buffers: ArrayBuffer[] = [await file.arrayBuffer()];
    const documentId = cryptoService.createUUID();
    const documentMetadata: DocumentMetadata = {
      name: file.name,
      type: file.type,
      size: file.size,
      document_id: documentId,
    };
    if (imageService.isImage(file.type)) {
      const scaledImages = await imageService.scale(file);
      buffers.push(scaledImages.smallImage);
      buffers.push(scaledImages.normalImage);
      documentMetadata.small_image_id = cryptoService.createUUID();
      documentMetadata.preview_image_id = cryptoService.createUUID();
      await documentMetadataRepository.createDocumentMetadata(documentMetadata);
      const encryptedDocuments = await cryptoService.encrypt(
        documentId,
        buffers
      );
      await Promise.all([
        documentRepository.createDocument(
          documentMetadata.document_id,
          encryptedDocuments[0]
        ),
        documentRepository.createDocument(
          documentMetadata.small_image_id,
          encryptedDocuments[1]
        ),
        documentRepository.createDocument(
          documentMetadata.preview_image_id,
          encryptedDocuments[2]
        ),
      ]);
      return {
        documentId: documentMetadata.document_id,
        name: documentMetadata.name,
        content: buffers[0],
      };
    } else {
      await documentMetadataRepository.createDocumentMetadata(documentMetadata);
      const encryptedDocuments = await cryptoService.encrypt(
        documentId,
        buffers
      );
      await documentRepository.createDocument(
        documentMetadata.document_id,
        encryptedDocuments[0]
      );
      return {
        documentId: documentMetadata.document_id,
        name: documentMetadata.name,
      };
    }
  },

  unlockDocuments: async (devices: Device[]) => {
    const currentUserId = authenticationService.getCurrentUser().uid;
    const currentDeviceId = deviceRepository.findCurrentDeviceId();
    if (currentDeviceId === null) {
      return Promise.reject("This device is not registered");
    }
    const documentKeys = await deviceRepository.findDocumentKeys(
      currentUserId,
      currentDeviceId
    );
    await Promise.all(
      devices.map((device) => encryptDocumentKeys(documentKeys, device))
    );
  },

  getDocument: async (documentMetadata: DocumentMetadata) => {
    let content = null;
    if (documentMetadata.small_image_id) {
      const encryptedDocument = await documentRepository
        .findDocument(documentMetadata.small_image_id)
        .catch((e) => {
          console.log(
            "Error loading document content " +
              documentMetadata.small_image_id +
              ": " +
              e
          );
          return null;
        });
      if (encryptedDocument) {
        content = await cryptoService
          .decrypt(documentMetadata.document_id, encryptedDocument)
          .catch((e) => {
            console.log(
              "Error decrypting document " +
                documentMetadata.document_id +
                ": " +
                e
            );
            return null;
          });
      }
    }
    return {
      documentId: documentMetadata.document_id,
      name: documentMetadata.name,
      content,
    } as Document;
  },
};

async function encryptDocumentKeys(
  documentKeys: DocumentKey[],
  device: Device
): Promise<void> {
  const encryptedDocumentKeys = await cryptoService.encryptDocumentKeys(
    documentKeys,
    device
  );
  return deviceRepository.storeDocumentKeys(device, encryptedDocumentKeys);
}
