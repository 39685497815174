export default interface User {
  account_id: string;
  user_id: string;
  email: string;
  display_name?: string;
  photo_url?: string;
}

export const getDisplayName = (users: User[], userId: string) => {
  return users.find((user) => user.user_id === userId)?.display_name;
};
