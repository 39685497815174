import { collection, doc, getDoc, setDoc } from "firebase/firestore";
import { JsonWebKeyPair } from "./JsonWebKeyPair";
import { firebaseApplication } from "../firebase/Firebase";

export const cryptoRepository = {
  findSymmetricKey: async (userId: string) => {
    const firestore = firebaseApplication.getFirestore();
    const keys = collection(firestore, "symmetric_keys");
    const key = await getDoc(doc(keys, userId));
    if (!key.exists()) {
      return Promise.reject("No symmetric key found");
    }
    return key.data() as JsonWebKey;
  },

  storeSymmetricKey: async (userId: string, key: JsonWebKey) => {
    const firestore = firebaseApplication.getFirestore();
    const keys = collection(firestore, "symmetric_keys");
    await setDoc(doc(keys, userId), key);
  },

  findKeyPair: (): JsonWebKeyPair | null => {
    return null; // will be overridden
  },

  findEncryptedPrivateKey: (): string | null => {
    return localStorage.getItem("privateKey");
  },

  storeEncryptedPrivateKey: (encryptedPrivateKey: string) => {
    localStorage.setItem("privateKey", encryptedPrivateKey);
  },
};
