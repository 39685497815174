import { doc, getDoc } from "firebase/firestore";
import {
  authenticationService,
  firebaseApplication,
} from "../firebase/Firebase";
import Account from "./Account";

export const accountRepository = {
  findAccount: async () => {
    const firestore = firebaseApplication.getFirestore();
    const accountId = await authenticationService.getCurrentAccountId();
    const reference = doc(firestore, "accounts", accountId);
    const accountDocument = await getDoc(reference);
    const account = accountDocument.data() as unknown as Account;
    account.account_id = accountId;
    return account;
  },
};
