import { Box, Fab, SxProps, TextField } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { useContext, useState } from "react";
import { UserContext } from "../user/UserContext";
import AccountInvitation from "./AccountInvitation";
import { ApproveDialogComponent } from "../component/ApproveDialogComponent";
import { accountInvitationService } from "./AccountInvitationService";

type AccountInvitationButtonProperties = {
  onInvitationCreated: (invitation: AccountInvitation) => void;
};

export default function AccountInvitationButton({
  onInvitationCreated,
}: AccountInvitationButtonProperties) {
  const fabStyle = {
    position: "absolute",
    bottom: { xs: "72", sm: "16" },
    right: 16,
    zIndex: 1050,
  } as SxProps;
  const user = useContext(UserContext);
  const [open, setOpen] = useState(false);
  const [invitationEmail, setInvitationEmail] = useState<string>("");
  const invitationAction = async () => {
    const invitation = await accountInvitationService.invite(
      user,
      invitationEmail
    );
    onInvitationCreated(invitation);
    setOpen(false);
  };
  return (
    <>
      <Box sx={{ width: "100%" }}>
        <Fab
          sx={fabStyle}
          color="primary"
          aria-label="add"
          component="label"
          onClick={() => setOpen(true)}
        >
          <AddIcon />
        </Fab>
      </Box>
      <ApproveDialogComponent
        title="Add account invitation"
        text="Enter the email of the person you want to invite:"
        approveText="OK"
        cancelText="Cancel"
        open={open}
        onCancel={() => setOpen(false)}
        onApprove={invitationAction}
      >
        <TextField
          id="invitation-email"
          label="Invitation Email"
          variant="standard"
          onChange={(e) => setInvitationEmail(e.target.value)}
        />
      </ApproveDialogComponent>
    </>
  );
}
