import { useState } from "react";
import { NavigationEntry } from "./navigation/NavigationEntry";
import HomePage from "./Home";
import DocumentsPage from "./document/DocumentsPage";
import ContactsPage from "./Contacts";
import SettingsPage from "./settings/SettingsPage";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Authentication from "./Authentication";
import MenuAppBar from "./MenuAppBar";
import AppBottomNavigation from "./navigation/AppBottomNavigation";
import { Box } from "@mui/material";
import DevicesListComponent from "./device/DevicesListComponent";
import NavigationSidebar from "./navigation/NavigationSidebar";
import UsersList from "./user/UsersList";
import AccountComponent from "./account/AccountComponent";
import { cryptoService } from "./crypto/CryptoService";
import { UserContext } from "./user/UserContext";
import User from "./user/User";

function App() {
  const [selectedNavigationEntry, setSelectedNavigationEntry] =
    useState<NavigationEntry>(NavigationEntry.HOME);
  const [user, setUser] = useState<User>();
  return (
    <>
      {!user && (
        <Authentication
          onUserAuthenticated={(user: User) => {
            cryptoService.decryptKeyPair(user.user_id);
            setUser(user);
          }}
        />
      )}
      {user && (
        <UserContext.Provider value={user}>
          <BrowserRouter>
            <Box>
              <MenuAppBar title={selectedNavigationEntry} />
              <Box display="flex">
                <NavigationSidebar
                  selectedNavigationEntry={NavigationEntry.SETTINGS}
                  setSelectedNavigationEntry={() => {}}
                />
                <Routes>
                  <Route path="/" element={<HomePage />} />
                  <Route path="contacts" element={<ContactsPage />} />
                  <Route path="documents" element={<DocumentsPage />} />
                  <Route path="settings" element={<SettingsPage />}>
                    <Route path="account" element={<AccountComponent />} />
                    <Route path="users" element={<UsersList />} />
                    <Route path="devices" element={<DevicesListComponent />} />
                  </Route>
                </Routes>
              </Box>
              <AppBottomNavigation
                selectedNavigationEntry={selectedNavigationEntry}
                setSelectedNavigationEntry={setSelectedNavigationEntry}
              />
            </Box>
          </BrowserRouter>
        </UserContext.Provider>
      )}
    </>
  );
}

export default App;
